import styled from "styled-components";

export const NavLink = styled.a`
  color: var(--colorUIPrimary);
  font-size: var(--step-2);
  text-transform: lowercase;
  position: relative;
  transition: color 150ms ease-out;

  &:hover {
    color: var(--colorUISecondary);
    cursor: pointer;
  }

  &.active {
    color: var(--colorUISecondary);
  }

  @media screen and (min-width: 768px) {
    font-size: var(--step-1-5);
  }
`;

export const WYSIWYG = styled.div`
  color: var(--colorContentPrimary);
  & > p {
    font-size: var(--step-3-5);
    font-weight: 200;
    & + p {
      margin-top: var(--step-3-5);
    }
  }

  & a {
    text-decoration: underline;
    &:hover {
      color: var(--colorBrandPink);
      cursor: pointer;
    }
  }
`;
export const ProductDescription = styled.div`
  margin-top: var(--step-2);
  color: var(--colorContentPrimary);
  & > p {
    font-size: var(--step-2);
    font-weight: 200;
    & + p {
      margin-top: var(--step-1);
    }
  }

  & > ul {
    margin-top: var(--step-3);
    margin-bottom: var(--step-1-5);
    & > li {
      font-size: var(--step-1-5);
      & + li {
        margin-top: calc(var(--gridColumnGap) * 0.5);
      }
    }
  }
`;

export const ProminentTitle = styled.span`
  color: var(--colorContentPrimary);
  font-size: var(--step-5);
  font-weight: 300;
  margin: 0;
  padding: var(--step-2) 0;
`;

export const MetaLabel = styled.div`
  color: var(--colorContentPrimary);
  font-size: var(--step-2);
  font-weight: 300;
`;

export const BodyCopySmall = styled.span`
  color: var(--colorContentPrimary);
  font-size: var(--step-2);
  font-weight: 300;
`;
