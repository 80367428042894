import { useEffect } from "react";
import Link from "next/link";
import styled from "styled-components";
import ActiveLink from "./ActiveLink";
import { NavLink } from "./styles/TextStyles";
import { useCart } from "react-use-cart";
import { useStore } from "../pages/_app";
import { motion } from "framer-motion";

export default function Header() {
  const { isEmpty, setItems } = useCart();

  useEffect(() => {
    // do this to make sure multiple tabs are always in sync
    const onReceiveMessage = (e) => {
      setItems(JSON.parse(e.newValue).items);
    };

    window.addEventListener("storage", onReceiveMessage);
    return () => {
      window.removeEventListener("storage", onReceiveMessage);
    };
  }, []);

  const uiScheme = useStore((state) => state.uiScheme);
  const headerTransparent = useStore((state) => state.headerTransparent);

  const variants = {
    full: { opacity: 1, scale: 1 },
    empty: { opacity: 0, scale: 0 },
  };

  return (
    <HeaderGrid scheme={uiScheme} transparent={headerTransparent}>
      <HeaderBranding>
        <Link href="/" passHref>
          <LogoNavLink>Veikkanen</LogoNavLink>
        </Link>
      </HeaderBranding>
      <HeaderNav>
        <ActiveLink
          activeClassName="active"
          key={"work-link"}
          href="/work"
          passHref
        >
          <NavLink>Work</NavLink>
        </ActiveLink>
        <ActiveLink
          activeClassName="active"
          key={"about-link"}
          href="/about"
          passHref
        >
          <NavLink>About</NavLink>
        </ActiveLink>
        <ActiveLink
          activeClassName="active"
          key={"contact-link"}
          href="/contact"
          passHref
        >
          <NavLink>Contact</NavLink>
        </ActiveLink>
        <ActiveLink
          activeClassName="active"
          key={"cart-link"}
          href="/cart"
          passHref
        >
          <NavLink>
            Cart
            <CartIndicator
              animate={isEmpty ? "empty" : "full"}
              variants={variants}
              transition={{ type: "spring", stiffness: 200 }}
            >
              •
            </CartIndicator>
          </NavLink>
        </ActiveLink>
      </HeaderNav>
    </HeaderGrid>
  );
}

const HeaderGrid = styled.header`
  display: grid;
  align-content: center;
  position: fixed;
  width: 100%;
  top: 0;
  height: var(--headerHeight);
  grid-template-columns: repeat(2, 1fr);
  column-gap: var(--gridColumnGap);
  padding: 0 var(--gridMarginGap);
  grid-template-areas: "a b";

  background-color: ${(props) => (props.transparent ? `transparent` : `white`)};

  transition: background-color 150ms ease-out;

  --colorUIPrimary: ${(props) =>
    props.scheme === "light"
      ? `var(--colorBrandCream)`
      : `var(--colorBrandTeal)`};

  z-index: 900;
`;

const HeaderBranding = styled.div`
  grid-area: a;
  text-transform: lowercase;
`;
const LogoNavLink = styled(NavLink)`
  font-weight: 400;

  transition: color 150ms ease-out;
`;

const HeaderNav = styled.div`
  justify-content: flex-end;
  text-align: right;
  grid-area: b;
  ${NavLink} {
    & + ${NavLink} {
      margin-left: var(--gridColumnGap);
    }
  }
`;

const CartIndicator = styled(motion.div)`
  position: absolute;
  right: -0.5em;
  top: -0.5em;
  // transform: translateX(40%) translateY(-40%);
  font-size: var(--step-2);
  display: inline-block;
  color: var(--colorBrandPink);
  padding-left: 0.5em;
  text-transform: uppercase;
`;
