import { useEffect } from "react";
import { useRouter } from "next/router";
import { createGlobalStyle } from "styled-components";
import Head from "next/head";
import "../styles/globals.css";
import { CartProvider, useCart } from "react-use-cart";
import { AnimatePresence } from "framer-motion";
import create from "zustand";
import * as gtag from "../lib/gtag";
import "../components/styles/reset.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Script from "next/script";
import "intersection-observer";

export const useStore = create((set) => ({
  uiScheme: "dark",
  setLightUIScheme: () => set({ uiScheme: "light" }),
  setDarkUIScheme: () => set({ uiScheme: "dark" }),

  headerTransparent: true,
  setHeaderTransparent: () => set({ headerTransparent: true }),
  setHeaderOpaque: () => set({ headerTransparent: false }),
}));

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: "Haffer";
  src: url("/fonts/HafferXH-Light.woff2"), url("/fonts/HafferXH-Light.woff");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "Haffer";
  src: url("/fonts/HafferXH-Regular.woff2"), url("/fonts/HafferXH-Regular.woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

:root {

  // Fluid type concepts from https://utopia.fyi/type/calculator
  --fluid-min-width: 768;
  --fluid-max-width: 2400;

  --fluid-screen: 100vw;
  --fluid-bp: calc(
    (var(--fluid-screen) - var(--fluid-min-width) / 16 * 1rem) /
      (var(--fluid-max-width) - var(--fluid-min-width))
  );

  /* Colors */
  --colorBrandCream: #F9EFE7;
  --colorBrandTeal: #004F51;
  --colorBrandEmerald: #006F43;
  --colorBrandGreen: #00984C;
  --colorBrandBlue: #A3D9E7;
  --colorBrandOrange: #FAA21B;
  --colorBrandPink: #F599B1;

  --colorContentPrimary: var(--colorBrandTeal);
  --colorContentSecondary: var(--colorBrandPink);
  --colorBackground: var(--colorBrandCream);
  --colorBackground: white;
  --colorUIPrimary: var(--colorBrandTeal);
  --colorUISecondary: var(--colorBrandPink);

  --bodyFont: "Moderat", sans-serif;
  --headingFont: "Hatton", serif;
  --brandFont: "Haffer", sans-serif;
}

@media screen and (max-width: 768px) {
  :root {
    --fluid-screen: calc(var(--fluid-min-width) * 1px);
  }
}

@media screen and (min-width: 2400px) {
  :root {
    --fluid-screen: calc(var(--fluid-max-width) * 1px);
  }
}

:root {
  --f-1-min: 8.00;
  --f-1-max: 14.00;
  --step-1: calc(
    ((var(--f-1-min) / 16) * 1rem) + (var(--f-1-max) - var(--f-1-min)) *
      var(--fluid-bp)
  );

  --f-1-25-min: 10.00;
  --f-1-25-max: 16.00;
  --step-1-25: calc(
    ((var(--f-1-25-min) / 16) * 1rem) + (var(--f-1-25-max) - var(--f-1-25-min)) *
      var(--fluid-bp)
  );

  --f-1-5-min: 12.00;
  --f-1-5-max: 24.00;
  --step-1-5: calc(
    ((var(--f-1-5-min) / 16) * 1rem) + (var(--f-1-5-max) - var(--f-1-5-min)) *
      var(--fluid-bp)
  );

  --f-1-75-min: 14.00;
  --f-1-75-max: 28.00;
  --step-1-75: calc(
    ((var(--f-1-75-min) / 16) * 1rem) + (var(--f-1-75-max) - var(--f-1-75-min)) *
      var(--fluid-bp)
  );

  --f-2-min: 16.00;
  --f-2-max: 32.00;
  --step-2: calc(
    ((var(--f-2-min) / 16) * 1rem) + (var(--f-2-max) - var(--f-2-min)) *
      var(--fluid-bp)
  );

  --f-2-25-min: 18.00;
  --f-2-25-max: 36.00;
  --step-2-25: calc(
    ((var(--f-2-25-min) / 16) * 1rem) + (var(--f-2-25-max) - var(--f-2-25-min)) *
      var(--fluid-bp)
  );

  --f-2-5-min: 20.00;
  --f-2-5-max: 40.00;
  --step-2-5: calc(
    ((var(--f-2-5-min) / 16) * 1rem) + (var(--f-2-5-max) - var(--f-2-5-min)) *
      var(--fluid-bp)
  );

  --f-3-min: 24.00;
  --f-3-max: 44.00;
  --step-3: calc(
    ((var(--f-3-min) / 16) * 1rem) + (var(--f-3-max) - var(--f-3-min)) *
      var(--fluid-bp)
  );

  --f-3-5-min: 28.00;
  --f-3-5-max: 48.00;
  --step-3-5: calc(
    ((var(--f-3-5-min) / 16) * 1rem) + (var(--f-3-5-max) - var(--f-3-5-min)) *
      var(--fluid-bp)
  );

  --f-4-min: 32.00;
  --f-4-max: 40.00;
  --step-4: calc(
    ((var(--f-8-min) / 16) * 1rem) + (var(--f-8-max) - var(--f-8-min)) *
      var(--fluid-bp)
  );

  --f-4-5-min: 36.00;
  --f-4-5-max: 44.00;
  --step-4-5: calc(
    ((var(--f-4-5-min) / 16) * 1rem) + (var(--f-4-5-max) - var(--f-4-5-min)) *
      var(--fluid-bp)
  );

  --f-5-min: 40.00;
  --f-5-max: 72.00;
  --step-5: calc(
    ((var(--f-5-min) / 16) * 1rem) + (var(--f-5-max) - var(--f-5-min)) *
      var(--fluid-bp)
  );

  --f-6-min: 48.00;
  --f-6-max: 96.00;
  --step-6: calc(
    ((var(--f-6-min) / 16) * 1rem) + (var(--f-6-max) - var(--f-6-min)) *
      var(--fluid-bp)
  );

  --f-7-min: 56.00;
  --f-7-max: 64.00;
  --step-7: calc(
    ((var(--f-7-min) / 16) * 1rem) + (var(--f-7-max) - var(--f-7-min)) *
      var(--fluid-bp)
  );

  --f-8-min: 64.00;
  --f-8-max: 72.00;
  --step-8: calc(
    ((var(--f-8-min) / 16) * 1rem) + (var(--f-8-max) - var(--f-8-min)) *
      var(--fluid-bp)
  );

  --f-9-min: 72.00;
  --f-9-max: 80.00;
  --step-9: calc(
    ((var(--f-9-min) / 16) * 1rem) + (var(--f-9-max) - var(--f-9-min)) *
      var(--fluid-bp)
  );

  --f-10-min: 80.00;
  --f-10-max: 88.00;
  --step-10: calc(
    ((var(--f-10-min) / 16) * 1rem) + (var(--f-10-max) - var(--f-10-min)) *
      var(--fluid-bp)
  );

  --f-11-min: 88.00;
  --f-11-max: 96.00;
  --step-11: calc(
    ((var(--f-11-min) / 16) * 1rem) + (var(--f-11-max) - var(--f-11-min)) *
      var(--fluid-bp)
  );

  --f-12-min: 96.00;
  --f-12-max: 104.00;
  --step-12: calc(
    ((var(--f-12-min) / 16) * 1rem) + (var(--f-12-max) - var(--f-12-min)) *
      var(--fluid-bp)
  );

  /* Needs customising */
  --gridColumnCount: 6;
  --projectGridColumnCount: 6;
  --gridColumnGap: 8px;
  --gridMarginGapRelative: calc( 24 / 1280 );
  /* --gridMarginGap: calc(var(--gridMarginGapRelative) * 100vw); */
  --gridMarginGap: 16px;
  --gridTemplateColumnsDefault: repeat( var(--gridColumnCount), minmax(0,1fr) );

  --projectGridTemplateColumnsDefault: repeat( var(--projectGridColumnCount), minmax(0,1fr) );

  --headerHeight: calc(var(--gridMarginGap) * 4);
  --footerHeight: calc(var(--gridMarginGap) * 6);

  @media screen and (min-width: 768px){

    --gridMarginGap: 32px;
    --gridColumnCount: 12;
    --projectGridColumnCount: 10;
    --gridColumnGap: calc(12 / 1280 * 100vw);
    --headerHeight: calc(var(--gridMarginGap) * 2);
    --footerHeight: calc(var(--gridMarginGap) * 2);
  }

  }
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-font-smoothing: antialiased;
    -webkit-font-smoothing: antialias !important;
    -moz-font-smoothing: antialias !important;
    font-smoothing: antialias !important;
  }

  html {
    min-height: -webkit-fill-available;
  }

  body {
    min-height: 100vh;
    min-height: -webkit-fill-available;

    background-color: var(--colorBackground);
    font-family: var(--brandFont);
    font-weight: 300;
  }

  // html,
  // body,
  // body > div:first-child,
  // div#__next,
  // div#__next > div:not(header),
  // div#__next > div:not(footer)
  // {
  //   //height: 100%;
  // }
`;

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      <Head>
        <link
          rel="preload"
          href="/fonts/HafferXH-Light.woff2"
          as="font"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/HafferXH-Regular.woff2"
          as="font"
          crossOrigin=""
        />
        <title>veikkanen</title>
        <meta
          name="description"
          content="A Melbourne based label, focusing on textile pieces that currently range from cushions to wall based artwork."
        />
        <meta property="og:title" content="veikkanen" />
        <meta property="og:image" content="/oGraph.jpg" />
      </Head>
      <GlobalStyle />
      <CartProvider>
        <Header />
        <AnimatePresence
          exitBeforeEnter
          initial={false}
          onExitComplete={() => window.scrollTo(0, 0)}
        >
          <Component {...pageProps} key={router.route} />
        </AnimatePresence>
        <Footer />
      </CartProvider>
    </>
  );
}

export default MyApp;
