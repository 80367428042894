import Link from "next/link";
import ActiveLink from "./ActiveLink";
import { NavLink } from "./styles/TextStyles";
import styled from "styled-components";

export default function Footer() {
  return (
    <FooterGrid>
      <FooterNav>
        {/* <ActiveLink
          activeClassName="active"
          key={"care-link"}
          href="/care"
          passHref
        >
          <NavLink>Care</NavLink>
        </ActiveLink>
        <ActiveLink
          activeClassName="active"
          key={"shipping-link"}
          href="/shipping"
          passHref
        >
          <NavLink>Shipping</NavLink>
        </ActiveLink> */}
      </FooterNav>
      <FooterLinks>
        <ActiveLink
          activeClassName="active"
          key={"commissions-link"}
          href="/commissions"
          passHref
        >
          <NavLink>Commissions</NavLink>
        </ActiveLink>
        <ActiveLink
          activeClassName="active"
          key={"subscribe-link"}
          href="/subscribe"
          passHref
        >
          <NavLink>Subscribe</NavLink>
        </ActiveLink>
        <NavLink
          href={`https://instagram.com/veikkanen_label`}
          target="_blank"
          rel="noreferrer"
        >
          Instagram
        </NavLink>
      </FooterLinks>
    </FooterGrid>
  );
}

const FooterGrid = styled.footer`
  position: relative;
  background-color: white;
  z-index: 500;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: var(--gridColumnGap);
  height: var(--footerHeight);
  padding: 0 var(--gridMarginGap);
  grid-template-areas: "a b";
  align-items: start;

  @media screen and (min-width: 768px) {
    align-items: center;
  }
`;

const FooterNav = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--gridColumnGap);
  grid-area: a;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: flex-end;
  gap: var(--gridColumnGap);
  grid-area: b;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;
